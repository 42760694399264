export { default as activeChecked } from '@/assets/icons/activeChecked.svg';
export { default as adjustments } from '@/assets/icons/adjustments.svg';
export { default as arrow } from '@/assets/icons/arrow.svg';
export { default as arrowBack } from '@/assets/icons/arrowBack.svg';
export { default as arrowForward } from '@/assets/icons/arrowForward.svg';
export { default as arrowIconCircle } from '@/assets/icons/arrowIconCircle.svg';
export { default as arrowLeft } from '@/assets/icons/arrowLeft.svg';
export { default as arrowLink } from '@/assets/icons/arrowLink.svg';
export { default as arrowNext } from '@/assets/icons/arrowNext.svg';
export { default as arrowPrev } from '@/assets/icons/arrowPrev.svg';
export { default as arrowRight } from '@/assets/icons/arrowRight.svg';
export { default as arrowTop } from '@/assets/icons/arrowTop.svg';
export { default as bathroom } from '@/assets/icons/bathroom.svg';
export { default as bedroom } from '@/assets/icons/bedroom.svg';
export { default as blackArrowDown } from '@/assets/icons/blackArrowDown.svg';
export { default as boldSearch } from '@/assets/icons/boldSearch.svg';
export { default as briefcase } from '@/assets/icons/briefcase.svg';
export { default as buildHome } from '@/assets/icons/buildHome.svg';
export { default as bulb } from '@/assets/icons/bulb.svg';
export { default as calendar } from '@/assets/icons/calendar.svg';
export { default as calendarTick } from '@/assets/icons/calendarTick.svg';
export { default as car } from '@/assets/icons/car.svg';
export { default as check } from '@/assets/icons/check.svg';
export { default as checked } from '@/assets/icons/checked.svg';
export { default as chevronDown } from '@/assets/icons/chevronDown.svg';
export { default as chevronDownWhite } from '@/assets/icons/chevronDownWhite.svg';
export { default as chevronUpWhite } from '@/assets/icons/chevronUpWhite.svg';
export { default as cinema } from '@/assets/icons/cinema.svg';
export { default as circularArrow } from '@/assets/icons/circularArrow.svg';
export { default as clock } from '@/assets/icons/clock.svg';
export { default as close } from '@/assets/icons/close.svg';
export { default as coffee } from '@/assets/icons/coffee.svg';
export { default as colorSwatch } from '@/assets/icons/colorSwatch.svg';
export { default as copySuccess } from '@/assets/icons/copySuccess.svg';
export { default as crown } from '@/assets/icons/crown.svg';
export { default as cube } from '@/assets/icons/cube.svg';
export { default as data } from '@/assets/icons/data.svg';
export { default as devices } from '@/assets/icons/devices.svg';
export { default as diningArea } from '@/assets/icons/diningArea.svg';
export { default as document } from '@/assets/icons/document.svg';
export { default as documentCad } from '@/assets/icons/documentCad.svg';
export { default as documentDefault } from '@/assets/icons/documentDefault.svg';
export { default as documentExcel } from '@/assets/icons/documentExcel.svg';
export { default as documentPdf } from '@/assets/icons/documentPdf.svg';
export { default as documents } from '@/assets/icons/documents.svg';
export { default as documentUploader } from '@/assets/icons/documentUploader.svg';
export { default as documentWord } from '@/assets/icons/documentWord.svg';
export { default as documentZip } from '@/assets/icons/documentZip.svg';
export { default as dollar } from '@/assets/icons/dollar.svg';
export { default as download } from '@/assets/icons/download.svg';
export { default as downloadBlack } from '@/assets/icons/downloadBlack.svg';
export { default as driver } from '@/assets/icons/driver.svg';
export { default as editBlack } from '@/assets/icons/editBlack.svg';
export { default as elevator } from '@/assets/icons/elevator.svg';
export { default as email } from '@/assets/icons/email.svg';
export { default as envelop } from '@/assets/icons/envelop.svg';
export { default as exclamationMark } from '@/assets/icons/exclamationMark.svg';
export { default as expandable } from '@/assets/icons/expandable.svg';
export { default as eye } from '@/assets/icons/eye.svg';
export { default as facebook } from '@/assets/icons/facebook.svg';
export { default as files } from '@/assets/icons/files.svg';
export { default as filledArrowIconCircle } from '@/assets/icons/filledArrowIconCircle.svg';
export { default as filterTick } from '@/assets/icons/filterTick.svg';
export { default as fingerPrint } from '@/assets/icons/fingerPrint.svg';
export { default as fingerPrintWhite } from '@/assets/icons/fingerPrintWhite.svg';
export { default as gear } from '@/assets/icons/gear.svg';
export { default as general } from '@/assets/icons/general.svg';
export { default as generalHouse } from '@/assets/icons/generalHouse.svg';
export { default as geoLocation } from '@/assets/icons/geoLocation.svg';
export { default as gpsPosition } from '@/assets/icons/gpsPosition.svg';
export { default as grid } from '@/assets/icons/grid.svg';
export { default as guestRoom } from '@/assets/icons/guestRoom.svg';
export { default as gym } from '@/assets/icons/gym.svg';
export { default as halfCircleArrow } from '@/assets/icons/halfCircleArrow.svg';
export { default as heart } from '@/assets/icons/heart.svg';
export { default as helmetSafety } from '@/assets/icons/helmetSafety.svg';
export { default as home } from '@/assets/icons/home.svg';
export { default as homeGray } from '@/assets/icons/homeGray.svg';
export { default as homeMove } from '@/assets/icons/homeMove.svg';
export { default as info } from '@/assets/icons/info.svg';
export { default as infoCalculator } from '@/assets/icons/infoCalculator.svg';
export { default as infoNew } from '@/assets/icons/infoNew.svg';
export { default as instagram } from '@/assets/icons/instagram.svg';
export { default as instagramInline } from '@/assets/icons/instagramInline.svg';
export { default as jaggedCheck } from '@/assets/icons/jaggedCheck.svg';
export { default as key } from '@/assets/icons/key.svg';
export { default as kitchen } from '@/assets/icons/kitchen.svg';
export { default as laundry } from '@/assets/icons/laundry.svg';
export { default as layout } from '@/assets/icons/layout.svg';
export { default as leftArrow } from '@/assets/icons/leftArrow.svg';
export { default as like } from '@/assets/icons/like.svg';
export { default as livingRom } from '@/assets/icons/livingRom.svg';
export { default as locationPin } from '@/assets/icons/locationPin.svg';
export { default as maid } from '@/assets/icons/maid.svg';
export { default as maidRoom } from '@/assets/icons/maidRoom.svg';
export { default as mail } from '@/assets/icons/mail.svg';
export { default as majlis } from '@/assets/icons/majlis.svg';
export { default as mapPlaceholder } from '@/assets/icons/mapPlaceholder.svg';
export { default as marker } from '@/assets/icons/marker.svg';
export { default as markerSecondary } from '@/assets/icons/markerSecondary.svg';
export { default as markerSelected } from '@/assets/icons/markerSelected.svg';
export { default as markerSelectedSecondary } from '@/assets/icons/markerSelectedSecondary.svg';
export { default as meetingCalendar } from '@/assets/icons/meetingCalendar.svg';
export { default as menu } from '@/assets/icons/menu.svg';
export { default as minus } from '@/assets/icons/minus.svg';
export { default as mobile } from '@/assets/icons/mobile.svg';
export { default as money } from '@/assets/icons/money.svg';
export { default as mouse } from '@/assets/icons/mouse.svg';
export { default as mouseBlack } from '@/assets/icons/mouseBlack.svg';
export { default as multipleUsers } from '@/assets/icons/multipleUsers.svg';
export { default as nonExpandable } from '@/assets/icons/nonExpandable.svg';
export { default as office } from '@/assets/icons/office.svg';
export { default as officeBag } from '@/assets/icons/officeBag.svg';
export { default as openBox } from '@/assets/icons/openBox.svg';
export { default as pantry } from '@/assets/icons/pantry.svg';
export { default as parking } from '@/assets/icons/parking.svg';
export { default as parkingSign } from '@/assets/icons/parkingSign.svg';
export { default as penRuler } from '@/assets/icons/penRuler.svg';
export { default as people } from '@/assets/icons/people.svg';
export { default as peopleWhite } from '@/assets/icons/peopleWhite.svg';
export { default as phone } from '@/assets/icons/phone.svg';
export { default as pin } from '@/assets/icons/pin.svg';
export { default as play } from '@/assets/icons/play.svg';
export { default as plus } from '@/assets/icons/plus.svg';
export { default as plusCircle } from '@/assets/icons/plusCircle.svg';
export { default as poiSelected } from '@/assets/icons/poiSelected.svg';
export { default as profile } from '@/assets/icons/profile.svg';
export { default as profileCircle } from '@/assets/icons/profileCircle.svg';
export { default as question } from '@/assets/icons/question.svg';
export { default as questionGear } from '@/assets/icons/questionGear.svg';
export { default as questionOutlined } from '@/assets/icons/questionOutlined.svg';
export { default as rating } from '@/assets/icons/rating.svg';
export { default as refresh } from '@/assets/icons/refresh.svg';
export { default as rightArrow } from '@/assets/icons/rightArrow.svg';
export { default as rocket } from '@/assets/icons/rocket.svg';
export { default as roomKey } from '@/assets/icons/roomKey.svg';
export { default as roundedTick } from '@/assets/icons/roundedTick.svg';
export { default as search } from '@/assets/icons/search.svg';
export { default as serviceTimes } from '@/assets/icons/serviceTimes.svg';
export { default as share } from '@/assets/icons/share.svg';
export { default as signOut } from '@/assets/icons/signOut.svg';
export { default as sofa } from '@/assets/icons/sofa.svg';
export { default as star } from '@/assets/icons/star.svg';
export { default as starEmpty } from '@/assets/icons/starEmpty.svg';
export { default as starFill } from '@/assets/icons/starFill.svg';
export { default as starHalf } from '@/assets/icons/starHalf.svg';
export { default as support } from '@/assets/icons/support.svg';
export { default as swimmingpool } from '@/assets/icons/swimmingpool.svg';
export { default as tableEtiquette } from '@/assets/icons/tableEtiquette.svg';
export { default as teayseer } from '@/assets/icons/teayseer.svg';
export { default as test123 } from '@/assets/icons/test123.svg';
export { default as tickMark } from '@/assets/icons/tickMark.svg';
export { default as timelineKey } from '@/assets/icons/timelineKey.svg';
export { default as trowelBricks } from '@/assets/icons/trowelBricks.svg';
export { default as twitter } from '@/assets/icons/twitter.svg';
export { default as twoChevronDown } from '@/assets/icons/twoChevronDown.svg';
export { default as uaeFlag } from '@/assets/icons/uaeFlag.svg';
export { default as uploadDocuments } from '@/assets/icons/uploadDocuments.svg';
export { default as user } from '@/assets/icons/user.svg';
export { default as userHelmet } from '@/assets/icons/userHelmet.svg';
export { default as userHelmetSafety } from '@/assets/icons/userHelmetSafety.svg';
export { default as users } from '@/assets/icons/users.svg';
export { default as userTick } from '@/assets/icons/userTick.svg';
export { default as utility } from '@/assets/icons/utility.svg';
export { default as vendor } from '@/assets/icons/vendor.svg';
export { default as wave } from '@/assets/icons/wave.svg';
export { default as web } from '@/assets/icons/web.svg';
export { default as website } from '@/assets/icons/website.svg';
export { default as yellowInfo } from '@/assets/icons/yellowInfo.svg';
export { default as youtube } from '@/assets/icons/youtube.svg';
export { default as zoomOutArrow } from '@/assets/icons/zoomOutArrow.svg';
