import { defineStyle } from '@chakra-ui/react';

const baseStyle = defineStyle({
  root: {
    '.backgroundImage': {
      zIndex: -2,
      objectFit: 'cover',
      objectPosition: {
        base: '65%',
        md: 'center',
      },
    },
    '.container': {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
      overflow: 'hidden',
      position: 'relative',
      height: '100%',
      padding: 0,
      paddingTop: {
        base: '40px',
        lg: '120px',
      },
      gap: {
        base: '26px',
        md: '40px',
        xl: '50px',
        '2xl': '60px',
        '4xl': '80px',
      },
      _rtl: {
        flexDir: 'column',
      },
    },
    '.title': {
      h2: {
        color: 'white',
        fontSize: 'clamp(4rem, 4vw, 8rem)',
        fontWeight: 300,
        whiteSpace: 'pre-wrap',
        textAlign: 'start',
        height: { base: 'auto', md: '60px', '4xl': '85px' },
      },
    },
    '.subtitle': {
      p: {
        color: 'white',
        fontSize: '16px',
        maxW: '330px',
        fontWeight: 300,
        textIndent: '-42px',
        paddingLeft: '42px',
        paddingRight: '0px',
      },
      _rtl: {
        p: {
          paddingLeft: '0px',
          paddingRight: '42px',
        },
      },
    },
  },
  centerTitlesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    alignItems: 'center',
    marginBottom: { base: '40px', md: 'auto', xl: 'auto', '4xl': '240px' },
  },
  downloadLinksWrapper: {
    display: 'flex',
    gap: { base: '30px', md: '10px' },
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: { base: 'column', md: 'row' },
  },
  mobileImageWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
    maxHeight: '313px',
    height: '100%',
    alignItems: 'flex-end',
    // marginLeft: { base: '-200px', md: 0 },
    img: {
      minWidth: '197px',
      height: { base: '200px', md: '310px', xl: '271px', '4xl': '460px' },
      width: 'auto',
      objectFit: 'contain',
    },
  },
});

export default baseStyle;
