import { defineStyle } from '@chakra-ui/react';

import colors from '@/styles/themes/brand/colors';
import FontSizes from '@/styles/themes/brand/fontSizes';
import FontWeights from '@/styles/themes/brand/fontWeights';

const style = defineStyle({
  modalOverlay: {
    bg: 'none',
    backdropFilter: 'auto',
    backdropInvert: '20%',
    backdropBlur: '3px',
  },
  modalContainer: {
    borderRadius: '8px',
    overflow: 'hidden',
  },
  mainContainer: {
    marginTop: '30px',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: colors.background,
    height: '100%',
  },
  iconContainer: {
    justifyContent: 'flex-end',
    width: '100%',
  },
  iconButton: {
    padding: '0px 30px',
  },
  icon: {
    height: '20px',
    width: '20px',
  },
  logo: {
    marginBottom: '25px',
  },
  textContainer: {
    marginBottom: '25px',
  },
  text: {
    textAlign: 'center',
    padding: '0px 35px',
  },
  buttonContainer: {
    marginBottom: '25px',
  },
  button: {
    backgroundColor: colors.secondaryHover,
    _hover: {
      background: colors.secondaryHover,
      boxShadow: 'none',
    },
  },
  buttonText: {
    color: 'white',
    fontSize: FontSizes.small,
    fontWeight: FontWeights.semibold,
  },
});

export default style;
