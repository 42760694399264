import { defineStyle } from '@chakra-ui/react';

import colors from '@/styles/themes/brand/colors';
import FontWeights from '@/styles/themes/brand/fontWeights';

const style = defineStyle({
  outerContainer: {
    paddingLeft: { base: '0', md: '25px', lg: '25px' },
    paddingTop: '10px',
    paddingRight: { base: '0', lg: '25px' },
    width: '100%',
  },
  iconContainer: {
    gap: { base: '55px', lg: '30px' },
    width: '100%',
    justifyContent: 'space-between',
  },
  profileIcon: {
    marginTop: '5px',
  },
  innerContainer: {
    padding: '0px 10px',
    paddingBottom: '15px',
    flexDirection: 'column',
  },
  starIcon: {
    width: '16px',
    height: '16px',
    color: colors.primary,
  },
  reviewText: {
    fontWeight: '600',
    color: colors.secondary,
    fontSize: '12px',
    whiteSpace: { md: 'nowrap' },
  },
  ratingContent: {
    maxWidth: '120px',
    height: '24px',
    minWidth: '50px',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  divider: {
    width: '1px',
    height: '12px',
    background: '#485173',
    border: 'none',
  },
  popoverContainer: {
    paddingTop: '10px',
    // paddingLeft: { base: '14px', lg: '0' },
  },
  infoIcon: {
    paddingRight: '5px',
  },
  popoverOneContentContainer: {
    background: '#1A2550',
    padding: '4',
    marginTop: '4px',
    marginBottom: '4px',
    position: 'relative',
    zIndex: '1000',
  },
  ratingInfoText: {
    color: 'white',
    fontSize: '12px',
    fontWeight: '400',
  },
  arrowIcon: {
    width: '16px',
    height: '16px',
  },
  popoverTwoContentContainer: {
    width: '280px',
  },
  popoverHeaderContainer: {
    paddingLeft: '15px',
    paddingTop: '15px',
  },
  popoverHeaderIcon: {
    width: '30px',
    height: '30px',
    marginTop: '10px',
  },
  popoverHeaderOne: {
    color: colors.brand.primary,
    fontWeight: FontWeights.semibold,
  },
  popoverHeaderTwo: { paddingBottom: '15px', color: colors.secondary },
  popoverRatingContainer: {
    alignItems: 'center',
    padding: '10px',
  },
  rateBreakdown: {
    gap: '3px',
  },
  rateProgressContainer: {
    width: '100%',
    px: '10px',
  },
  rateProgress: {
    borderRadius: '10px',
  },
});

export default style;
