/*
    Created this modal so that the users should get the option to download
    the app when they are attempting to sign in to the app from the browser
    in their mobile devices.
 */
import {
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { default as NextImage } from 'next/image';
import { useRouter } from 'next/router';

import style from './DownloadMobileAppModal.style';
import DownloadMobileAppModalProps from './DownloadMobileAppModal.types';

import TeyaseerDownloadAppImage from '@/assets/images/teyaseer-download-app.png';
import TeyaseerLogo from '@/assets/images/teyaseer-logo.png';
import { Icon, Text } from '@/components';
import useTranslation from '@/hooks/useTranslate';

const DownloadMobileAppModal = ({
  isOpen,
  onClose,
  redirectTo,
}: DownloadMobileAppModalProps) => {
  const { t } = useTranslation();
  const router = useRouter();

  const pushToDataLayer = (eventAction: any) => {
    if (typeof window !== 'undefined') {
      const _window: any = window;
      _window.dataLayer = _window.dataLayer || [];
      _window.dataLayer.push({
        event: eventAction,
        action: eventAction,
      });
    }
  };

  const handleClose = () => {
    try {
      pushToDataLayer('close_download_button_clicked');
      if (redirectTo) {
        router.push(redirectTo);
      }
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleAppDownload = () => {
    try {
      pushToDataLayer('download_button_clicked');

      if (typeof window !== 'undefined') {
        const deviceType = navigator.userAgent;

        // android detection
        if (/android/i.test(deviceType)) {
          const playStoreRoute =
            'https://play.google.com/store/apps/details?id=app.teyaseer.ae&hl=en_IN';
          router.push(playStoreRoute);
        }

        // iOS detection
        else if (/iPad|iPhone|iPod/.test(deviceType)) {
          const appStoreRoute =
            'https://apps.apple.com/us/app/teyaseer/id6477573411';
          router.push(appStoreRoute);
        } else {
          alert('This app is only available for Android and ios devices');
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      size={'xl'}
      isCentered
      closeOnOverlayClick={false}
      autoFocus={false}
    >
      <ModalOverlay sx={style.modalOverlay} />
      <ModalContent sx={style.modalContainer}>
        <Flex sx={style.mainContainer}>
          <Flex sx={style.iconContainer}>
            <Button
              variant={'unstyled'}
              onClick={handleClose}
              sx={style.iconButton}
              id="modal-close-button"
            >
              <Icon name="close" sx={style.icon} />
            </Button>
          </Flex>
          <Flex sx={style.logo}>
            <NextImage src={TeyaseerLogo} alt="teyaseer-logo" />
          </Flex>
          <Flex sx={style.textContainer}>
            <Text sx={style.text}>{t('portal_download_app')}</Text>
          </Flex>
          <Flex sx={style.buttonContainer}>
            <Button
              variant={'primary'}
              sx={style.button}
              onClick={handleAppDownload}
              id="modal-download-button"
            >
              <Text sx={style.buttonText}>
                {t('portal_download_app_button')}
              </Text>
            </Button>
          </Flex>
          <Flex>
            <NextImage
              src={TeyaseerDownloadAppImage}
              alt="teyaseer-download-app"
            />
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default DownloadMobileAppModal;
