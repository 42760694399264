import { SVGProps } from 'react';

export default function Logo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 160 46"
      fill="none"
      width="160"
      height="40"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M.025 28.042v3.178h5.06V45h3.709V31.22h5.032v-3.178H.025ZM21.374 28.042V45h12.245v-3.127h-8.561v-3.941h8.38V34.83h-8.38v-3.687h8.56v-3.101H21.375ZM52.605 28.042l-4.254 6.814-4.307-6.814h-4.203l6.667 10.017V45h3.684v-6.94l6.616-10.018h-4.203ZM85.628 39.733a7.416 7.416 0 0 0 5.325 2.215c1.958 0 2.913-.911 2.913-1.846 0-1.23-1.458-1.65-3.391-2.092-2.737-.615-6.253-1.353-6.253-5.02 0-2.733 2.41-4.948 6.354-4.948 2.661 0 4.871.787 6.53 2.29L95.12 32.89c-1.356-1.231-3.164-1.797-4.796-1.797-1.608 0-2.462.69-2.462 1.674 0 1.108 1.406 1.451 3.34 1.895 2.763.615 6.279 1.427 6.279 5.07 0 3.002-2.184 5.267-6.706 5.267-3.213 0-5.524-1.059-7.107-2.609l1.96-2.658ZM105.366 28.042V45h12.245v-3.127h-8.561v-3.941h8.379V34.83h-8.379v-3.687h8.561v-3.101h-12.245ZM125.677 28.042V45h12.245v-3.127h-8.561v-3.941h8.379V34.83h-8.379v-3.687h8.561v-3.101h-12.245ZM66.716 28.042 60.024 45h4.046l4.956-13.831L73.98 45h4.021l-6.667-16.958h-4.618ZM156.105 38.542c1.868-.432 3.787-2.034 3.787-5.034 0-3.153-2.204-5.466-5.81-5.466h-8.094v3.098l7.548.004c1.48 0 2.596.915 2.596 2.338 0 1.424-1.116 2.34-2.596 2.34l-7.548.002v9.175h3.684v-6.076h2.697L155.768 45h4.229l-3.892-6.457ZM156.094 8.213v7.163c0 1.084-.512 1.437-1.665 1.437H123.199c-1.76 0-2.881-.605-2.881-1.69v-6.91h-3.906v5.927c0 1.942-.033 2.673-1.633 2.673H109.166c-1.152 0-1.664-.353-1.664-1.437V1h-3.906v15.082c0 2.345 1.152 3.43 4.066 3.43h8.078c2.176 0 3.457-.328 4.098-1.387.728 1.055 4.873 1.337 6.966 1.38.054.002.104.007.162.007h28.999c2.882 0 4.035-1.085 4.035-3.43V8.213h-3.906Z"
        clipRule="evenodd"
      />
      <path
        fill="#EC972D"
        fillRule="evenodd"
        d="M151.701 1.085h3.815v3.742h-3.815M156.185 4.827H160V1.085h-3.815v3.742ZM107.771 24.293h3.815v-3.739h-3.815v3.74ZM112.254 24.293h3.816v-3.739h-3.816v3.74Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m13.135 24.25 6.05-16.984 4.458 12.241H75.64c2.11 0 3.26-.377 3.899-1.434.607.906 1.725 1.434 3.354 1.434h1.502c2.044 0 3.258-.377 3.865-1.41.608.882 1.726 1.41 3.355 1.41h1.502c3.099 0 4.312-.956 4.312-3.422V8.233h-3.897v5.914c0 2.29-.192 2.668-2.396 2.668-1.438 0-2.428-.554-2.428-1.712v-4.731H84.81v4.379c0 1.837-.32 2.064-2.332 2.064-1.662 0-2.492-.554-2.492-1.687v-4.756h-3.898v4.379c0 1.56-.192 2.064-1.63 2.064H26.195L21.263 4.366h-4.155l-6.55 17.11H0v2.774h13.135Z"
        clipRule="evenodd"
      />
      <path
        fill="#EC972D"
        fillRule="evenodd"
        d="M17.358 19.17h3.816v-3.739h-3.816v3.74ZM17.358 24.27h3.816v-3.738h-3.816v3.739ZM67.12 44.994h3.815v-3.74h-3.816v3.74Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
