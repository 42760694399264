import { envVars } from '@/configs/env';

export const DATADOG_APP_ID = envVars.NEXT_PUBLIC_DATADOG_APP_ID;
export const DATADOG_CLIENT_TOKEN = envVars.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;
export const DATADOG_SITE = envVars.NEXT_PUBLIC_DATADOG_SITE;
export const DATADOG_SERVICE = envVars.NEXT_PUBLIC_DATADOG_SERVICE;
export const DATADOG_ENV = envVars.NEXT_PUBLIC_DATADOG_ENV;
export const DATADOG_VERSION = envVars.NEXT_PUBLIC_DATADOG_VERSION;
export const DATADOG_DEFAULTPRIVACYLEVEL = 'mask-user-input';
export const DATADOG_ALLOWTRACINGURL =
  envVars.NEXT_PUBLIC_DATADOG_ALLOWTRACINGURL;
export const SESSIONSAMPLERATE = 100;
export const SESSIONREPLAYSAMPLERATE = 20;
export const TRACKUSERINTERACTIONS =
  envVars.NEXT_PUBLIC_DATADOG_TRACKUSERINTERACTIONS;
export const TRACKRESOURCES = envVars.NEXT_PUBLIC_DATADOG_TRACKRESOURCES;
export const TRACKLONGTASKS = envVars.NEXT_PUBLIC_DATADOG_TRACKLONGTASKS;
export const FORWARDERRORSTOLOGS =
  envVars.NEXT_PUBLIC_DATADOG_FORWARDERRORSTOLOGS;
